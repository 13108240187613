import { MAINTAIN_PERMISSION, LINE_PERFORMANCE_PRODUCTION_LINES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Production Line", "search-by": "Code,Description,FacilityName,AreaName", "ph-search-by": "Search by keywords", "fetch-on-first-mount": false }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-1", attrs: { "icon": "edit", "size": "small", "type": "link", "disabled": record.isInactive }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("text-field", { key: "Code", attrs: { "data-index": "code", "title": "Code" } }), _c("text-field", { key: "FacilityName", attrs: { "data-index": "facilityName", "title": "Facility" } }), _c("text-field", { key: "AreaName", attrs: { "data-index": "areaName", "title": "Department" } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Description" } })], 1);
};
var staticRenderFns$1 = [];
var ProductionLine_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "ProductionLine",
  inject: ["crud", "getArea", "can"],
  data() {
    return {
      permissions: { maintain: MAINTAIN_PERMISSION }
    };
  },
  computed: {
    area() {
      return this.getArea();
    }
  },
  watch: {
    area(newVal) {
      this.crud.setFilter("AreaId", {
        operator: "Eq",
        value: newVal ? newVal : -1
      });
      this.fetchList();
    }
  },
  created() {
    this.crud.setFilter("AreaId", {
      operator: "Eq",
      value: this.area ? this.area : -1
    });
    this.fetchList();
  },
  methods: {
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ProductionLine = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "line-performance.productionlines", "api-url": _vm.apiUrl, "create-route": "/line-performance/production-line/create", "edit-route": "/line-performance/production-line/:id", "page": _vm.page } }, [_c("production-line")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ProductionLine
  },
  data() {
    return {
      page: LINE_PERFORMANCE_PRODUCTION_LINES,
      apiUrl,
      menuItems: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "production-line",
          title: "Production Line",
          path: "/line-performance/production-line"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
